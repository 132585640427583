.vertical-timeline-element-emoji-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(33, 150, 243);
    color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.vertical-timeline-element-date-text {
    font-size: 1rem !important;
    font-weight: bold !important;
}